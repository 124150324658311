import { useGenerateNote, useGeneratePdfFromSections } from '@/hooks/api'
import { Sidebar } from './Sidebar'
import useStore from '@/store'
import { formatTemplateResponse } from '@/util'

export const NoteSidebar = () => {
  const encounter = useStore(state => state.encounter)
  const generatePdf = useGeneratePdfFromSections()
  const activeTemplate = useStore(state => state.activeTemplate)
  const generateNote = useGenerateNote()

  if (!encounter) return null

  const getFormattedNote = (preserveFormatting = false) => {
    if (!encounter.note) return ''

    const formattedNote = formatTemplateResponse(activeTemplate, encounter.note, encounter.deletedSections)
    return preserveFormatting ? formattedNote.replace(/\n/g, '<br/>') : formattedNote
  }

  return (
    <Sidebar
      title="Note"
      getFormattedContent={getFormattedNote}
      generateContent={generateNote}
      generatePdf={generatePdf}
      viewName="Note"
      contentKey="note"
      isGeneratingKey="isGeneratingNote"
      activeTemplate={activeTemplate}
      diposthogMsg="note"
      isEHRIntegrationEnabled={true}
    />
  )
}
