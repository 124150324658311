import useStore from '@/store'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

export const ProtectedRoute = () => {
  const navigate = useNavigate()
  const isAuthenticated = useStore(state => state.isAuthenticated)
  const params = new URLSearchParams(window.location.search)
  const licenseKey = params.get('licenseKey')
  if (licenseKey) {
    useStore
      .getState()
      .validateLicenseKey(licenseKey)
      .then(isValid => {
        if (isValid) {
          useStore.getState().storeAuthentication({ license_key: licenseKey })
          navigate('/')
        }
      })
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/authenticate" />
}
