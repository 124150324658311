import useStore from "@/store"
import { Sidebar } from "./Sidebar"
import { useGenerateInsuranceNarrative, useGeneratePdfFromSections } from "@/hooks/api"
import { data } from "@/data"

export const InsuranceSidebar = () => {

  const encounter = useStore(state => state.encounter)
  const generateInsuranceNarrative = useGenerateInsuranceNarrative()
  const generatePdf = useGeneratePdfFromSections()

  if (!encounter) return null

  const getFormattedInsurance = () => {
    if (!encounter.insuranceNarrative) return ''
    const sections = encounter.insuranceNarrative?.sections
    return sections?.narrative ?? ''
  }
  
  return (
        <Sidebar
          title="Insurance Narrative"
          getFormattedContent={getFormattedInsurance}
          generateContent={generateInsuranceNarrative}
          generatePdf={generatePdf}
          viewName="SoapExamInsuranceNarrative"
          contentKey="insuranceNarrative"
          isGeneratingKey="isGeneratingInsuranceNarrative"
          activeTemplate={data.insuranceNarrative}
          diposthogMsg="insurance_narrative"
        />
  )
}
