import { ITranscriptionResponse } from '@/models'
import { IGeneratedTemplate, SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import { useStore } from '@/store'
import { useEffect } from 'react'
import { Fillings } from './Fillings'
import { RootCanalFillings } from './RootCanalFillings'
import { CrownPermanent } from './CrownPermanent'
import { CrownTemporary } from './CrownTemporary'
import * as Sentry from '@sentry/react'

const socket = SocketApi.getInstance();

export const Templates = () => {
  const activeTemplate = useStore(state => state.activeTemplate)
  const values = useStore(state => state.templateValues)
  const setTemplateValues = useStore(state => state.setTemplateValues)
  const updateEncounter = useStore(state => state.updateEncounter)

  useEffect(() => {
    try {
      socket.onUniqueListener(SocketApiListenEvents.REALTIME_TRANSCRIPT, (data: ITranscriptionResponse) => {
        if (data.final && data.transcript && data.transcript.length > 5) {
          const rawTranscript = `${useStore.getState().encounter?.rawTranscript || ''} ${data.transcript}`.trim()
          console.log('RAWTRANSCRIPT', rawTranscript)
          updateEncounter({ rawTranscript })

          // Check if connected before sending data to template
          if (socket.isConnected()) {
            socket.generateTemplate(activeTemplate?.id as string, rawTranscript)
          } else {
            console.log('Socket is not connected. Cannot send data to template.')
            Sentry.addBreadcrumb({ message: 'Socket is not connected. Cannot send data to template.' })
          }
        }
      })

      socket.onUniqueListener(SocketApiListenEvents.TEMPLATE_GENERATED, (data: IGeneratedTemplate) => {
        setTemplateValues(data.template)
        console.log('DATA', data)
      })
    } catch (error) {
      console.error('Error in socket event handling:', error)
      Sentry.captureException(error)
    }
  }, [socket.getSocket()])

  return (
    <div id="template" className="mb-12 flex flex-col pb-32">
      {activeTemplate?.id === 'FILLINGS' && <Fillings values={values} />}
      {activeTemplate?.id === 'ROOT_CANAL_FILLINGS' && <RootCanalFillings values={values} />}
      {activeTemplate?.id === 'CROWN_PERMANENT' && <CrownPermanent values={values} />}
      {activeTemplate?.id === 'CROWN_TEMPORARY' && <CrownTemporary values={values} />}
    </div>
  )
}
