import { useGeneratePatientSummary, useGeneratePdfFromSections } from '@/hooks/api'
import { useStore } from '@/store'
import { formatPatientSummary } from '@/util'
import { Sidebar } from './Sidebar'
import { data } from '@/data'

export const SummarySidebar = () => {
  const encounter = useStore(state => state.encounter)
  const generatePdf = useGeneratePdfFromSections()
  const generateSummary = useGeneratePatientSummary()

  if (!encounter) return null

  return (
    <Sidebar
      title="Summary"
      getFormattedContent={() => (encounter.patientSummary ? formatPatientSummary(encounter.patientSummary) : '')}
      generateContent={generateSummary}
      generatePdf={generatePdf}
      viewName="Summary"
      contentKey="patientSummary"
      isGeneratingKey="isGeneratingSummary"
      activeTemplate={data.patientSummaryTemplate}
      diposthogMsg="summary"
      isEHRIntegrationEnabled={false}
    />
  )
}
