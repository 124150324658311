import useStore from '@/store'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

const SystemBanner = () => {
  const bannerMessage = useStore(state => state.bannerMessage)

  if (!bannerMessage) return null

  return (
    <div className="z-50 w-full bg-yellow-100 py-2 px-4 shadow-md">
      <div className="mx-auto flex max-w-4xl items-center justify-center gap-2 text-yellow-800 font-medium">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <span>{bannerMessage}</span>
      </div>
    </div>
  )
}

export default SystemBanner
