import posthog from 'posthog-js'
import { toast } from 'sonner'
import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { copyToClipboard, formatEncounterDate } from '@/util'
import { ReportIssue } from './ReportIssue'
import { SendToEhrButton } from './ui/sendtoehr-button'

export const Sidebar = ({
  title,
  getFormattedContent,
  generateContent,
  generatePdf,
  viewName,
  contentKey,
  isGeneratingKey,
  activeTemplate,
  diposthogMsg,
  isEHRIntegrationEnabled = false
}) => {
  const encounter = useStore(state => state.encounter)
  const setView = useStore(state => state.setView)
  const updateEncounter = useStore(state => state.updateEncounter)

  if (!encounter) return null

  const onRegenerateClick = async () => {
    updateEncounter({ isTranscribing: false, deletedSections: [] })
    posthog.capture(`user_regenerated_${diposthogMsg}`)
    generateContent.mutate(encounter)
    setView(viewName)
  }

  const onCopyClick = () => {
    const content = getFormattedContent()
    copyToClipboard(content)
      .then(() => posthog.capture(`user_copied_${diposthogMsg}`))
      .catch(error => {
        console.error(`Failed to copy ${title}:`, error)
        toast.error(`Failed to copy ${title} to clipboard`)
      })
  }

  const onDownloadClick = () => {
    posthog.capture(`user_downloaded_${diposthogMsg}`)
    const promise = generatePdf.mutateAsync({
      fileName: `${formatEncounterDate(encounter.createdAt)} ${title}`,
      activeTemplate: activeTemplate,
      response: encounter[contentKey],
      deletedSections: encounter.deletedSections
    })

    toast.promise(promise, {
      loading: 'Generating PDF...',
      success: `Downloaded ${title}`,
      error: 'Failed to generate PDF. Please try again.'
    })
  }

  return (
    <div className="p-8">
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col items-stretch gap-4">
          <Button size="lg" onClick={onCopyClick}>
            Copy {title}
          </Button>
          {isEHRIntegrationEnabled && <SendToEhrButton noteText={getFormattedContent(true)} />}

          <Button
            size="lg"
            variant="outline"
            onClick={onRegenerateClick}
            disabled={generateContent.isPending || encounter[isGeneratingKey]}
          >
            Regenerate {title}
          </Button>

          <Button
            size="lg"
            variant="outline"
            onClick={onDownloadClick}
            disabled={
              generateContent.isPending || encounter[isGeneratingKey] || generatePdf.isPending || !encounter[contentKey]
            }
          >
            Download {title}
          </Button>
        </div>

        <div className="mt-4">
          <ReportIssue />
        </div>

        <div className="mt-6 rounded-md border border-gray-200 bg-gray-100 px-4 py-3 text-sm text-gray-700 shadow-sm">
          <p className="text-center">
            <strong>Disclaimer:</strong> While we strive to make your note as accurate as possible, AI technology may
            not always be perfect. Please review and make any necessary edits.
          </p>
        </div>
      </div>
    </div>
  )
}
