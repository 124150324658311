import io from 'socket.io-client'
import { Socket } from 'socket.io-client'
import { toast } from 'sonner'

import {
  IPatientIntakeProgress,
  IPatientIntakeResponse,
  IPatientSummaryResponse,
  PatientIntakeProgressSchema,
  PatientIntakeResponseSchema,
  PatientSummaryResponseSchema
} from '@/data'
import * as Sentry from '@sentry/react'
import { useStore } from '@/store'

import { Microphone, MicrophoneEvent } from './Microphone'
import { z } from 'zod'
import { BANNER_MESSAGES } from '@/utils/constants'

export enum SocketApiListenEvents {
  TRANSCRIPT = 'transcript',
  NOTE_GENERATED = 'note-generated',
  CLINICAL_MAP_GENERATED = 'clinical-map-generated',
  ERROR = 'error',
  CONNECTION_ERROR = 'connect_error',
  CONNECTION_TIMEOUT = 'connect_timeout',
  RECONNECTING = 'reconnecting',
  RECONNECT_ERROR = 'reconnect_error',
  RECONNECT_FAILED = 'reconnect_failed',
  RECONNECT_ATTEMPT = 'reconnect_attempt',
  RECONNECTED = 'reconnect',
  DISCONNECTED = 'disconnect',
  CONNECTED = 'connect',
  REALTIME_TRANSCRIPT = 'realtime-transcription',
  TEMPLATE_GENERATED = 'template-generated',
  PATIENT_SUMMARY_GENERATED = 'patient-summary-generated',
  ERROR_NOTE_GENERATION = 'error-note-generation',
  ERROR_PATIENT_SUMMARY_GENERATION = 'error-patient-summary-generation',
  ERROR_REALTIME_TRANSCRIPTION = 'error-realtime-transcription',
  ERROR_CLINICAL_MAP_GENERATION = 'error-clinical-map-generation',
  ERROR_TEMPLATE_GENERATION = 'error-template-generation',
  ERROR_CUSTMO_TEMPLATE__NOTE_GENERATION = 'error-custom-template-note-generation',
  ERROR_CUSTOM_TEMPLATE_NOTE_GENERATION = 'error-custom-template-note-generation',
  ERROR_CUSTOM_TEMPLATE_CREATION = 'error-custom-template-creation',
  ERROR_CUSTOM_TEMPLATE_GENERATION = 'error-custom-template-generation',
  CLOSING_CONNECTION_MAX_DURATION = 'closing-connection-max-duration',
  CUSTOM_TEMPLATE_GENERATED = 'custom-template-generated',
  CUSTOM_TEMPLATE_CREATED = 'custom-template-created',
  CUSTOM_TEMPLATE_NOTE_GENERATED = 'custom-template-note-generated',
  PATIENT_NARRATIVE_GENERATED = 'patient-narrative-generated',
  INSURANCE_NARRATIVE_GENERATED = 'insurance-narrative-generated',
  SOAPNOTE_INSURANCE_NARRATIVE_GENERATED = 'soapnote-insurance-narrative-generated',
  DB_CUSTOM_TEMPLATES_FETCHED = 'db-custom-templates-fetched',
  DB_CUSTOM_TEMPLATE_SAVED = 'db-custom-template-saved',
  DB_CUSTOM_TEMPLATE_FETCH_ERROR = 'db-custom-template-fetch-error',
  DB_CUSTOM_TEMPLATE_SAVE_ERROR = 'db-custom-template-save-error',
  OPEN_AI_STATUS = 'openai-status'
}

export enum SocketApiEmitEvents {
  TRANSCRIBE = 'transcribe',
  GENERATE_NOTE = 'generate-note',
  GENERATE_PATIENT_SUMMARY = 'generate-patient-summary',
  GENERATE_CLINICAL_MAP = 'generate-clinical-map',
  GENERATE_TEMPLATE = 'generate-template',
  CREATE_CUSTOM_TEMPLATE = 'create-custom-template',
  GENERATE_CUSTOM_TEMPLATE = 'generate-custom-template',
  FINISH_ENCOUNTER = 'finish-encounter',
  GENERATE_CUSTOM_TEMPLATE_NOTE = 'generate-custom-template-note',
  GENERATE_CUSTOM_TEMPLATE_NARRATIVES = 'generate-custom-template-narrative',
  SAVE_CUSTOM_TEMPLATE = 'save-custom-template',
  FETCH_CUSTOM_TEMPLATES = 'fetch-custom-templates',
  UPDATE_CUSTOM_TEMPLATE = 'update-custom-template',
  DELETE_CUSTOM_TEMPLATE = 'delete-custom-template',
  TOGGLE_AMBIENT = 'toggle-ambient',
  GENERATE_SOAP_INSURANCE_NARRATIVE = 'generate-soap-insurance-narrative'
}

export interface IGenerateTemplate {
  type: string
  transcript: string
}

export interface IGeneratedTemplate {
  type: string
  template: string
}

export interface INote {
  sections: [
    {
      name: string
      content: string
    }
  ]
}
export class SocketApi {
  private static instance: SocketApi | null = null
  private socket: Socket | null = null
  private microphone = new Microphone()
  private isReconnecting = false
  private lastErrorToastAt = 0
  private maxTimeout = false
  private isConnecting = false
  private microphoneListenerUnsubscribe: (() => void) | null = null
  private inactivityTimeout: ReturnType<typeof setTimeout> | null = null
  private readonly MAX_IDLE_TIME = 1000 * 60 * 20

  constructor() {
    if (SocketApi.instance) {
      return SocketApi.instance
    } else {
      SocketApi.instance = this
    }
    this.connect()
    useStore.subscribe((state, prevState) => {
      if (state.encounter?.isTranscribing !== prevState.encounter?.isTranscribing && state.encounter?.isTranscribing) {
        this.connect()
      }
    })
  }

  connect(): void {
    if (this.socket?.connected || this.isConnecting || this.isReconnecting) {
      return
    }
    this.isConnecting = true
    this.socket = io(import.meta.env.VITE_API, {
      timeout: 60000,
      transports: ['websocket'],
      transportOptions: {
        websocket: { withCredentials: false },
        polling: { withCredentials: false }
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 2000
    })
    this.attachConnectionSocketListeners()
    this.microphoneListener()
    this.socket.on('connect', () => {
      this.maxTimeout = false
      this.lastErrorToastAt = 0
      this.isConnecting = false
      this.resetInactivityTimeout()
      console.log('Connected to socket')
      if (this.isReconnecting) {
        this.isReconnecting = false
        toast.success('We have successfully reconnected to our server.')
      }
      setInterval(() => {
        const state = useStore.getState()
        if (
          !state.encounter?.isTranscribing ||
          !this.isConnected() ||
          state.activeTemplate?.isHardCoded ||
          state.activeCustomTemplate
        )
          return
        const sections = useStore.getState().encounter?.transcript?.sections
        if (!sections) return
        const plainText = Object.keys(sections)
          .reduce((acc: string[], key) => {
            acc.push(sections[key] ?? '')
            return acc
          }, [])
          .join('\n')
        this.generateClinicalMap(plainText)
      }, 10000)
    })
    this.socketListeners()
    this.generatedNoteListener()
    this.generatedCustomTemplateNoteListener()
    this.generatedPatientNarrativeListener()
    this.generatedPatientSummaryListener()
    this.generatedInsuranceNarrativeListener()
    this.generatedSoapnoteInsuranceNarrativeListener()
    this.generatedClinicalMapListener()
  }

  private attachConnectionSocketListeners(): void {
    if (!this.socket) return

    this.socket.io.on(SocketApiListenEvents.ERROR, data => {
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      toast.error(
        `We're having a hard time connecting to our server. Please wait while we attempt to reconnect. Thank you for your patience.`
      )
      console.error('Error  ', data)
      Sentry.addBreadcrumb({ message: `Socket Error ${data}`, level: 'error', category: 'Socket Connection' })
    })
    this.on(SocketApiListenEvents.CONNECTION_ERROR, data => {
      this.setTranscribing(false)
      // this means that automatic reconnections wouldn't take place
      if (!this.socket?.active) {
        this.disconnectAndCleanup()
      }
      useStore.getState().setIsPending(false)
      this.errorToast()
      Sentry.addBreadcrumb({
        message: `Socket Connection Error ${data}`,
        level: 'error',
        category: 'Socket Connection'
      })
    })
    this.on(SocketApiListenEvents.CONNECTION_TIMEOUT, data => {
      this.setTranscribing(false)
      this.disconnectAndCleanup()
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Socket Connection Timeout Error ${data}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Connection Timeout ', data)
    })
    this.socket.io.on(SocketApiListenEvents.RECONNECT_ERROR, () => {
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      this.errorToast()
      console.error('Reconnecting Error ')
      Sentry.addBreadcrumb({
        message: `Socket Connection Reconnect Error`,
        level: 'error',
        category: 'Socket Connection'
      })
    })
    this.socket.io.on(SocketApiListenEvents.RECONNECT_FAILED, () => {
      this.setTranscribing(false)
      this.disconnectAndCleanup()
      useStore.getState().setIsPending(false)
      this.errorToast(`Failed to reconnect to the server. Please try again.`)
      console.error('Reconnecting failed ')
      Sentry.addBreadcrumb({
        message: `Socket Connection Reconnect Error`,
        level: 'error',
        category: 'Socket Connection'
      })
    })
    this.socket.io.on(SocketApiListenEvents.RECONNECTED, attempt => {
      this.isReconnecting = false
      Sentry.addBreadcrumb({
        message: `Socket reconnect attempt successful: ${attempt}`,
        level: 'info',
        category: 'Socket Connection'
      })
      console.log('Socket reconnect attempt succesful:', attempt)
    })

    this.socket.io.on(SocketApiListenEvents.RECONNECT_ATTEMPT, attempt => {
      this.isReconnecting = true
      Sentry.addBreadcrumb({
        message: `Socket reconnect attempt: ${attempt}`,
        level: 'info',
        category: 'Socket Connection'
      })
      console.log('Socket reconnect attempt:', attempt)
    })

    this.on(SocketApiListenEvents.DISCONNECTED, e => {
      console.error('Socket disconnected', e)
      Sentry.addBreadcrumb({
        message: `Socket has been disconnected: ${e}`,
        level: 'info',
        category: 'Socket Connection'
      })
      this.setTranscribing(false)
      useStore.getState().setIsPending(false)
      if (!this.socket?.active) {
        this.disconnectAndCleanup()
      }
    })
  }

  public static getInstance(): SocketApi {
    if (!SocketApi.instance) {
      SocketApi.instance = new SocketApi()
    }
    return SocketApi.instance
  }

  private resetInactivityTimeout(): void {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout)
    }
    this.inactivityTimeout = setTimeout(() => {
      this.handleInactivityTimeout()
    }, this.MAX_IDLE_TIME)
  }

  private handleInactivityTimeout(): void {
    const isTranscribing = useStore.getState().encounter?.isTranscribing
    if (!isTranscribing && this.socket?.connected) {
      toast.error('Connection has timed out due to inactivity! Click the mic to reconnect.')
      this.setTranscribing(false)
      this.disconnectAndCleanup()
    } else {
      this.resetInactivityTimeout()
    }
  }

  isConnected(): boolean {
    return this.socket?.connected ?? false
  }

  on(event: SocketApiListenEvents, callback: (data: any) => void): void {
    this.socket?.on(event, callback)
  }

  onUniqueListener(event: SocketApiListenEvents, callback: (data: any) => void): void {
    this.socket?.removeAllListeners(event)
    this.socket?.on(event, callback)
  }

  emit(event: SocketApiEmitEvents, data: any): void {
    this.ensureConnected(() => {
      this.socket?.emit(event, data)
    })
  }

  microphoneListener(): void {
    if (this.microphoneListenerUnsubscribe) {
      this.microphoneListenerUnsubscribe()
    }

    this.microphoneListenerUnsubscribe = this.microphone.on(MicrophoneEvent.DATA, event => {
      const ambientMode = useStore.getState().ambientMode
      if (this.isConnected() && useStore.getState().encounter?.isTranscribing) {
        this.emit(SocketApiEmitEvents.TRANSCRIBE, {
          audioData: event.detail,
          ambientMode
        })
      } else if (useStore.getState().encounter?.isTranscribing) {
        this.setTranscribing(false)
        if (!this.maxTimeout) {
          this.errorToast()
        }
      }
    })
  }

  updateAmbientMode(): void {
    const ambientMode = useStore.getState().ambientMode
    this.emit(SocketApiEmitEvents.TOGGLE_AMBIENT, { ambientMode })
  }

  getMicrophone(): Microphone {
    return this.microphone
  }

  getSocket() {
    return this.socket
  }

  generateCustomTemplate(userTemplate: string, rawTranscript: string): void {
    if (!userTemplate) {
      Sentry.addBreadcrumb({
        message: 'User template not available',
        level: 'error',
        category: 'Generate Custom Template'
      })
      return
    }
    this.emit(SocketApiEmitEvents.GENERATE_CUSTOM_TEMPLATE, { userTemplate, rawTranscript })
  }

  ensureConnected(callback: () => void): void {
    if (this.isConnected()) {
      callback()
    } else {
      if (!this.isConnecting) {
        this.connect()
      }

      this.on(SocketApiListenEvents.CONNECTED, () => {
        callback()
      })
    }
  }

  generateNote(type: string, note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_NOTE, { type, note })
    useStore.getState().updateEncounter({ isGeneratingNote: true })
  }

  generateSoapInsurance(type: string, note: string | null) {
    this.emit(SocketApiEmitEvents.GENERATE_SOAP_INSURANCE_NARRATIVE, { type, note })
    useStore.getState().updateEncounter({ isGeneratingInsuranceNarrative: true })
  }

  generateCustomTemplateNote(note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_CUSTOM_TEMPLATE_NOTE, note)
    useStore.getState().updateEncounter({ isGeneratingNote: true })
  }

  generateNarrative(note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_CUSTOM_TEMPLATE_NARRATIVES, note)
  }

  generatePatientSummary(note: string | null): void {
    this.emit(SocketApiEmitEvents.GENERATE_PATIENT_SUMMARY, note)
    useStore.getState().updateEncounter({ isGeneratingPatientSummary: true })
  }

  generateClinicalMap(note: string): void {
    if (this.isConnected()) {
      this.emit(SocketApiEmitEvents.GENERATE_CLINICAL_MAP, note)
    } else {
      this.setTranscribing(false)
    }
  }

  generateTemplate(type: string, transcript: string): void {
    this.emit(SocketApiEmitEvents.GENERATE_TEMPLATE, { type, transcript })
  }

  createCustomTemplate(template: string): void {
    this.emit(SocketApiEmitEvents.CREATE_CUSTOM_TEMPLATE, template)
  }

  finishEncounter(): void {
    this.emit(SocketApiEmitEvents.FINISH_ENCOUNTER, {})
  }

  disconnectAndCleanup(): void {
    if (this.socket) {
      this.socket.removeAllListeners()
      this.socket.disconnect()
      this.socket = null
    }

    if (this.microphoneListenerUnsubscribe) {
      this.microphoneListenerUnsubscribe()
      this.microphoneListenerUnsubscribe = null
    }

    this.isConnecting = false
    this.isReconnecting = false
  }

  private generatedNoteListener(): void {
    this.on(SocketApiListenEvents.NOTE_GENERATED, (data: object) => {
      let note: IPatientIntakeResponse = { sections: {} }
      try {
        console.log(data)
        note = PatientIntakeResponseSchema.parse(data)
      } catch (e) {
        if (e instanceof z.ZodError) {
          console.log(e.issues)
        }
        console.error('Error parsing generated note', e)
        Sentry.addBreadcrumb({ message: `Error Parsing Note ${e}`, level: 'error', category: 'Socket Connection' })
      }

      if (note) {
        useStore.getState().updateEncounter({ note, isGeneratingNote: false, wasNoteUpdatedAfterGeneration: false })
      }
    })
  }

  private generatedCustomTemplateNoteListener(): void {
    this.on(SocketApiListenEvents.CUSTOM_TEMPLATE_NOTE_GENERATED, (data: any) => {
      try {
        if (data.template) {
          useStore.getState().updateEncounter({ isGeneratingNote: false })

          useStore.getState().setFinalNote(data.template)
          useStore.getState().setView('CustomTemplateEdit')
        }
      } catch (e) {
        console.error('Error parsing generated note', e)
        Sentry.addBreadcrumb({ message: `Error Parsing Note ${e}`, level: 'error', category: 'Socket Connection' })
      }
    })
  }

  private generatedPatientNarrativeListener(): void {
    this.on(SocketApiListenEvents.PATIENT_NARRATIVE_GENERATED, (data: any) => {
      if (data) {
        try {
          useStore.getState().setEditedPatientNarrative('')
          useStore.getState().setPatientNarrative(data.narrative)
        } catch (e) {
          console.error('Error parsing generated patient summary', e)
          Sentry.addBreadcrumb({
            message: `Error parsing generated patient summary ${e}`,
            level: 'error',
            category: 'Socket Connection'
          })
        }
      }
    })
  }

  private generatedSoapnoteInsuranceNarrativeListener() {
    this.on(SocketApiListenEvents.SOAPNOTE_INSURANCE_NARRATIVE_GENERATED, (data: any) => {
      let insuranceNarrative
      if (data) {
        try {
          insuranceNarrative = { sections: data }
        } catch (e) {
          if (e instanceof z.ZodError) {
            console.log(e.issues)
          }
          console.error('Error parsing generated note', e)
          Sentry.addBreadcrumb({ message: `Error Parsing Note ${e}`, level: 'error', category: 'Socket Connection' })
        }
      }

      if (insuranceNarrative) {
        useStore
          .getState()
          .updateEncounter({ insuranceNarrative, isGeneratingNote: false, isGeneratingInsuranceNarrative: false })
      }
    })
  }

  private generatedInsuranceNarrativeListener(): void {
    this.on(SocketApiListenEvents.INSURANCE_NARRATIVE_GENERATED, (data: any) => {
      if (data) {
        try {
          useStore.getState().setEditedInsuranceNarrative('')
          useStore.getState().setInsuranceNarrative(data.narrative)
        } catch (e) {
          console.error('Error parsing generated insurance narrative', e)
          Sentry.addBreadcrumb({
            message: `Error parsing generated insurance narrative ${e}`,
            level: 'error',
            category: 'Socket Connection'
          })
        }
      }
    })
  }
  saveCustomTemplate(licenseKey: string, template: object): void {
    this.emit(SocketApiEmitEvents.SAVE_CUSTOM_TEMPLATE, { licenseKey, template })
  }

  fetchCustomTemplates(licenseKey: string, callback: (data: any) => void): void {
    this.ensureConnected(() => {
      this.onUniqueListener(SocketApiListenEvents.DB_CUSTOM_TEMPLATES_FETCHED, callback)
      this.emit(SocketApiEmitEvents.FETCH_CUSTOM_TEMPLATES, licenseKey)
    })
  }

  updateCustomTemplate(template: object): void {
    this.emit(SocketApiEmitEvents.UPDATE_CUSTOM_TEMPLATE, template)
  }

  deleteCustomTemplate(id: string, licenseKey: string): void {
    this.emit(SocketApiEmitEvents.DELETE_CUSTOM_TEMPLATE, { id, licenseKey })
  }

  private generatedPatientSummaryListener(): void {
    this.on(SocketApiListenEvents.PATIENT_SUMMARY_GENERATED, (data: object) => {
      let patientSummary: IPatientSummaryResponse | null = null
      if (data) {
        try {
          patientSummary = PatientSummaryResponseSchema.parse(data)
        } catch (e) {
          console.error('Error parsing generated patient summary', e)
          Sentry.addBreadcrumb({
            message: `Error parsing generated patient summary ${e}`,
            level: 'error',
            category: 'Socket Connection'
          })
        }
      }

      if (patientSummary) {
        useStore.getState().updateEncounter({ patientSummary, isGeneratingPatientSummary: false })
      }
    })
  }

  private generatedClinicalMapListener(): void {
    this.on(SocketApiListenEvents.CLINICAL_MAP_GENERATED, (data: object) => {
      let clinicalMap: IPatientIntakeProgress | null = null

      try {
        console.log('Clinical Map: ', data)
        clinicalMap = PatientIntakeProgressSchema.parse({
          sections: Object.entries(data).reduce(
            (mapping, [sectionId, complete]) => ({
              ...mapping,
              [sectionId]: { progress: complete ? 100 : 0 }
            }),
            []
          )
        })
      } catch (e) {
        console.error('Error parsing generated clinical map', e)
      }

      if (clinicalMap) {
        useStore.getState().updateEncounter({ progress: clinicalMap })
      }
    })
  }

  private socketListeners(): void {
    this.on(SocketApiListenEvents.ERROR_NOTE_GENERATION, e => {
      useStore.getState().updateEncounter({ isGeneratingNote: false })
      toast.error(`There was an error generating your note.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Note Generation Error ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating note', e)
    })

    this.on(SocketApiListenEvents.ERROR_PATIENT_SUMMARY_GENERATION, e => {
      useStore.getState().updateEncounter({ isGeneratingPatientSummary: false })
      toast.error(`There was an error generating your patient summary.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Patient Summary Generation Error ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating patient summary', e)
    })

    this.on(SocketApiListenEvents.ERROR_CLINICAL_MAP_GENERATION, e => {
      toast.error(`There was an error generating your clinical map.`)
      Sentry.addBreadcrumb({
        message: `Socket Connection Clinical Map Generation Error ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating clinical map', e)
    })

    this.on(SocketApiListenEvents.ERROR_TEMPLATE_GENERATION, e => {
      toast.error(`There was an error generating your template.`)
      Sentry.addBreadcrumb({ message: `Error generating template ${e}`, level: 'error', category: 'Socket Connection' })
      console.error('Error generating template', e)
    })

    this.on(SocketApiListenEvents.ERROR_CUSTOM_TEMPLATE_NOTE_GENERATION, e => {
      toast.error(`There was an error generating your custom template note.`)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Error generating custom template note ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating custom template note', e)
    })

    this.on(SocketApiListenEvents.ERROR_CUSTOM_TEMPLATE_GENERATION, e => {
      toast.error(`There was an error generating your custom template.`)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Error generating custom template ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error generating custom template', e)
    })

    this.on(SocketApiListenEvents.ERROR_CUSTOM_TEMPLATE_CREATION, e => {
      toast.error(`There was an error creating your custom template.`)
      useStore.getState().setIsPending(false)
      Sentry.addBreadcrumb({
        message: `Error creating custom template ${e}`,
        level: 'error',
        category: 'Socket Connection'
      })
      console.error('Error creating custom template', e)
    })

    this.on(SocketApiListenEvents.DB_CUSTOM_TEMPLATE_SAVE_ERROR, error => {
      toast.error('Templates cannot be saved at the moment. Please try again later.')
      Sentry.addBreadcrumb({ message: `Templates saving error: ${error}`, level: 'info', category: 'Custom templates' })
      console.error(`Templates saving error: ${error}`)
    })

    this.on(SocketApiListenEvents.DB_CUSTOM_TEMPLATE_FETCH_ERROR, error => {
      toast.error('Templates cannot be fetched at the moment. Please try again later.')
      Sentry.addBreadcrumb({
        message: `Templates fetching error: ${error}`,
        level: 'info',
        category: 'Custom templates'
      })
      console.error(`Templates fetching error: ${error}`)
    })

    this.on(SocketApiListenEvents.OPEN_AI_STATUS, ({ openAiDown }) => {
      const setBannerMessage = useStore.getState().setBannerMessage
      if (openAiDown) {
        setBannerMessage(BANNER_MESSAGES.OPEN_AI_DOWN)
      } else {
        setBannerMessage(null)
      }
    })
  }

  private setTranscribing(isTranscribing: boolean): void {
    useStore.getState().updateEncounter({ isTranscribing })
  }

  private errorToast(message?: string): void {
    const allowedViews = ['Transcript', 'Template', 'CustomTemplate', 'CustomTemplateEdit']
    if (!allowedViews.includes(useStore.getState().view)) {
      return
    }

    if (Date.now() - this.lastErrorToastAt < 30000) return
    this.lastErrorToastAt = Date.now()

    toast.error(
      message ||
        `We're having a hard time connecting to our server. Please wait while we attempt to reconnect. Thank you for your patience.`
    )
  }
}
