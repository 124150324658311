import React from 'react';
import { useStore } from '@/store';
import NarrativeEditor from './customTemplate/NarrativeEditor';

export const SoapInsuranceNarrative: React.FC = () => {
  const { encounter, updateEncounter } = useStore(state => ({
    encounter: state.encounter,
    updateEncounter: state.updateEncounter,
  }));

  const setEditedInsuranceNarrative = (content) => {
    let insuranceNarrative = {sections: {...encounter?.insuranceNarrative?.sections, narrative: content} }
    updateEncounter({insuranceNarrative})
  }

  return (
    <NarrativeEditor
      narrative={encounter?.insuranceNarrative?.sections?.narrative!}
      editedNarrative={encounter?.insuranceNarrative?.sections?.narrative!}
      setEditedNarrative={setEditedInsuranceNarrative}
      placeholder="Edit your insurance narrative here..."
    />
  );
};

export default SoapInsuranceNarrative;
