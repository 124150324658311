import { Mic } from 'lucide-react'
import posthog from 'posthog-js'
import React, { useEffect } from 'react'

import { Button } from '@/components/ui'
import { useStaticTemplates } from '@/hooks/api'
import { ITranscriptionResponse, TemplateType } from '@/models'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import { useStore } from '@/store'
import { cn, formatResponse } from '@/util'
import { MicButton } from './MicButton'

interface ITextEditorProps {
  type: TemplateType
}

const socket = SocketApi.getInstance();

export const TextEditor: React.FC<ITextEditorProps> = ({ type }) => {
  const initialState = React.useRef(useStore.getState())
  const encounter = useStore(state => state.encounter)
  const hideTranscription = useStore(state => state.hideTranscription)
  const toggleSection = useStore(state => state.toggleSection)
  const isTranscribing = useStore(state => state.encounter?.isTranscribing)
  const isSpotTranscribing = useStore(state => state.encounter?.isSpotTranscribing)
  const updateEncounter = useStore(state => state.updateEncounter)
  const templates = useStaticTemplates()
  const [copiedIndex, setCopiedIndex] = React.useState<number | null>(null)
  const ambientMode = useStore(state => state.ambientMode)
  const textareaRefs = React.useRef<HTMLTextAreaElement[]>([])
  const micSpotBtn = React.useRef<HTMLButtonElement | null>(null)

  const getValue = (section): string => {
    const initialEncounter = initialState.current.encounter
    if (type === 'Transcript') {
      let content = ''
      content = ambientMode
        ? initialEncounter?.flatTranscript || ''
        : initialEncounter?.transcript?.sections[section.id] ?? ''
      return content.replace(/\n/g, '<br>')
    }
    if (type === 'Note') {
      const sectionContent = initialEncounter?.note?.sections[section.id] ?? ''
      return formatResponse(sectionContent)
    }
    const sectionContent = initialEncounter?.patientSummary?.sections[section.id] ?? ''
    return formatResponse(sectionContent)
  }

  const processDiarizedTranscription = (data: ITranscriptionResponse) => {
    const words = data.words || []
    if (!ambientMode || words.length === 0) {
      return { flatTranscript: data.transcript, speakerTranscript: data.transcript }
    }
    const flatTranscript = `[${new Date(words[0]?.start * 1000).toISOString().substr(14, 5)}] ${data.transcript}`

    let currentSpeaker = words[0]?.speaker ?? 0
    const speakerResult: string[] = []
    words.forEach((word, index) => {
      const timestamp = `[${new Date(word.start * 1000).toISOString().substr(14, 5)}]`
      const isNewSpeaker = word.speaker !== currentSpeaker || index === 0

      if (isNewSpeaker) {
        currentSpeaker = word.speaker ?? 0
        speakerResult.push(`Speaker ${currentSpeaker}: ${timestamp} ${word.word}`)
      } else {
        speakerResult[speakerResult.length - 1] += ` ${word.word}`
      }
    })

    return {
      flatTranscript,
      speakerTranscript: speakerResult.join('\n')
    }
  }

  const setValue = (section, value: string) => {
    let _encounter = {}
    if (type === 'Transcript') {
      _encounter = {
        ...encounter,
        wasNoteUpdatedAfterGeneration: encounter?.note ? true : false,
        transcript: {
          ...encounter?.transcript,
          sections: {
            ...encounter?.transcript?.sections,
            [section.id]: value
          }
        }
      }
    }
    if (type === 'Note') {
      _encounter = {
        ...encounter,
        note: {
          ...encounter?.note,
          sections: {
            ...encounter?.note?.sections,
            [section.id]: value
          }
        }
      }
    }
    if (type === 'PatientSummary') {
      _encounter = {
        ...encounter,
        patientSummary: {
          ...encounter?.patientSummary,
          sections: {
            ...encounter?.patientSummary?.sections,
            [section.id]: value
          }
        }
      }
    }
    useStore.getState().updateEncounter(_encounter)
  }

  const selectedRect = () => {
    try {
      const selection = window.getSelection()
      const selectedText = selection?.toString()
      const range = selection?.getRangeAt(0)
      const rect = range?.getBoundingClientRect()

      return { selectedText, rect, selection, range }
    } catch (_) {
      return {
        selectedText: null,
        rect: null,
        range: null,
        selection: null
      }
    }
  }

  useEffect(() => {
    if (type === 'Transcript') {
      socket.onUniqueListener(SocketApiListenEvents.REALTIME_TRANSCRIPT, (data: ITranscriptionResponse) => {
        const isSpotTranscribing = useStore.getState().encounter?.isSpotTranscribing
        if (data?.final) {
          console.log(`Transcription: ${data.transcript} ID: ${data.rId}`)
          if (isSpotTranscribing) {
            const { selectedText, range, selection } = selectedRect()
            if (selectedText && range && selection) {
              const scrollPosition = window.scrollY
              const selectedRange = selection.getRangeAt(0)
              const { startContainer, startOffset, endContainer, endOffset } = selectedRange

              if (startContainer?.textContent && endContainer?.textContent) {
                range.deleteContents()
                const transcriptText = ` ${data.transcript} `
                range.insertNode(document.createTextNode(transcriptText))
                range.collapse(false)

                window.scrollTo(0, scrollPosition)
                selection.removeAllRanges()

                const newStartOffset = Math.min(startOffset, startContainer.textContent.length)
                const newEndOffset = Math.min(
                  startOffset + transcriptText.length,
                  endContainer.textContent.length + transcriptText.length
                )

                selectedRange.setStart(startContainer, newStartOffset)
                selectedRange.setEnd(endContainer, newEndOffset)
                selection.addRange(selectedRange)

                const sectionId = (range.commonAncestorContainer as any).id
                const value = range.commonAncestorContainer.textContent || ''
                useStore.getState().updateEncounter({
                  transcript: {
                    sections: {
                      ...useStore.getState().encounter?.transcript?.sections,
                      [sectionId]: value
                    }
                  }
                })
              }
            }
          } else {
            const { flatTranscript, speakerTranscript } = processDiarizedTranscription(data)

            useStore.getState().addTranscriptionResponse({
              ...data,
              transcript: flatTranscript,
              flatTranscript,
              speakerTranscript
            })

            initialState.current = useStore.getState()
          }
        }
      })
    }
    updateEncounter({ isSpotTranscribing: false })
  }, [type, socket.getSocket()])

  const { template, response } =
    type === 'Transcript'
      ? { template: encounter?.transcriptTemplate, response: encounter?.transcript }
      : type === 'Note'
        ? {
            template: templates.data?.Note?.find(template => template.id === encounter?.templateId),
            response: encounter?.note
          }
        : { template: templates.data?.PatientSummary, response: encounter?.patientSummary }

  if (!encounter || !template) {
    return null
  }

  const handleSelect = () => {
    updateEncounter({ isSpotTranscribing: false })
    const { selectedText, rect } = selectedRect()
    if (selectedText && rect && micSpotBtn.current) {
      const middleX = rect.x + (rect.width - 40) / 2
      const y = rect.y - 50
      micSpotBtn.current.style.top = `${y}px`
      micSpotBtn.current.style.left = `${middleX}px`
      updateEncounter({ isSpotTranscribing: true })
    }
  }

  const toggleSpotMic = () => {
    if (isTranscribing) {
      const { selectedText } = selectedRect()
      updateEncounter({ isTranscribing: false, isSpotTranscribing: !!selectedText })
    } else {
      updateEncounter({ isTranscribing: true })
    }
  }

  const shouldShowTextEditor = !(hideTranscription && type === 'Transcript')

  const shouldUseAmbientMode = type === 'Transcript' && ambientMode

  return (
    <div className="mb-12 flex flex-col">
      <Button
        ref={el => {
          micSpotBtn.current = el
        }}
        className={cn(
          'fixed z-10 size-10 rounded-full border-2 border-white p-0 shadow transition-none',
          encounter?.isTranscribing ? 'bg-[#ff0000] hover:bg-[#ff3333]' : 'bg-[#5A71E4]',
          isSpotTranscribing ? 'flex' : 'hidden'
        )}
        onClick={toggleSpotMic}
      >
        <Mic className="size-5" color="#fff" />
      </Button>

      {hideTranscription && type === 'Transcript' && (
        <div className="absolute left-1/2 top-[calc(40%)] z-[999999] flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
          <MicButton />
        </div>
      )}

      {shouldShowTextEditor &&
        (shouldUseAmbientMode ? (
          <div
            className={`relative flex max-h-[50rem] max-w-[2000px] flex-col overflow-y-auto rounded-lg bg-white ${
              encounter?.flatTranscript ? '' : 'h-40'
            }`}
            style={{
              boxShadow: 'none',
              border: 'none',
              padding: '1rem'
            }}
            contentEditable={!isTranscribing}
            suppressContentEditableWarning={true}
            dangerouslySetInnerHTML={{
              __html: (encounter?.flatTranscript || '').replace(/\n/g, '<br>')
            }}
          />
        ) : (
          template.sections.map((section, index) => {
            const isDeleted = encounter.deletedSections.includes(section.id)

            return (
              <div key={section.id} className="group relative flex max-w-[800px] flex-col items-stretch">
                <label
                  htmlFor={section.id}
                  className={cn(
                    'absolute left-8 right-8 top-4 flex h-7 cursor-text flex-row items-center justify-between gap-2 md:justify-normal',
                    isDeleted && 'cursor-default'
                  )}
                >
                  <div
                    className={cn(
                      'relative text-lg text-primary transition-opacity duration-150 ease-out',
                      isDeleted && 'opacity-50'
                    )}
                  >
                    {section.name}
                  </div>
                </label>
                <div
                  ref={el => {
                    textareaRefs[index] = el
                  }}
                  id={section.id}
                  className={cn(
                    'w-full resize-none appearance-none px-8 pb-4 pt-12 text-lg outline-none focus:outline-none disabled:overflow-hidden disabled:bg-white group-last:pb-56',
                    type !== 'Transcript' && 'transition-all duration-150 ease-out',
                    isDeleted && 'invisible h-0 cursor-default pb-6 pt-8 text-white'
                  )}
                  contentEditable={!isTranscribing}
                  suppressContentEditableWarning={true}
                  onInput={(e: any) => setValue(section, e.currentTarget.textContent)}
                  onSelect={handleSelect}
                  dangerouslySetInnerHTML={{ __html: getValue(section) }}
                />
              </div>
            )
          })
        ))}
    </div>
  )
}
