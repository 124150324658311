import posthog from 'posthog-js'
import { useEffect, useState } from 'react'
import { useStore } from '@/store'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import back from '/assets/images/back-button.svg'
import { db } from '@/db'
import { CustomTemplate } from '@/interfaces/interface'
import * as Sentry from '@sentry/react'
import { data } from '@/data'

const Socket = SocketApi.getInstance();

const builtTemplates = data.procedureTemplates.map(template => template.id)

export const ProcedureSelect = () => {
  const { setActiveCustomTemplate, setActiveTemplate, updateEncounter, setView, startNewEncounter } = useStore()
  const [selectedTemplateId, setSelectedTemplateId] = useState('')
  const [dbTemplates, setDbTemplates] = useState<CustomTemplate[]>([])
  const [localTemplates, setLocalTemplates] = useState<CustomTemplate[]>([])

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const localStoredTemplates = await db.template?.toArray?.()
        setLocalTemplates(localStoredTemplates || [])

        Socket.fetchCustomTemplates(useStore.getState().licenseKey, templates => {
          setDbTemplates(templates || [])
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    fetchTemplates()

    Socket.onUniqueListener(SocketApiListenEvents.DB_CUSTOM_TEMPLATES_FETCHED, templates => {
      setDbTemplates(templates || [])
    })
  }, [Socket.getSocket()])

  const handleTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const templateId = event.target.value
    setSelectedTemplateId(templateId)

    if (builtTemplates.includes(templateId)) {
      const selectedTemplate = data.procedureTemplates.find(template => template.id === templateId)
      if (selectedTemplate) {
        setActiveTemplate(selectedTemplate)
        posthog.capture(`template_selected_${selectedTemplate.name}`)
        updateEncounter({ templateId })
      }
    } else {
      const customTemplate =
        dbTemplates.find(template => String(template.id) === templateId) ||
        localTemplates.find(template => String(template.id) === templateId)

      if (customTemplate) {
        setActiveCustomTemplate(customTemplate)
      }
    }
  }

  const handleBackButton = () => {
    setView('Setup')
    startNewEncounter()
    Socket.finishEncounter()
  }

  return (
    <div className="mt-5">
      <button
        onClick={handleBackButton}
        className="ml-30 fixed left-tenth bg-transparent text-lg text-[#010D83] hover:underline"
      >
        <span className="flex items-center">
          <img src={back} alt="Back icon" className="mr-2" />
          Back
        </span>
      </button>

      <div className="mt-4 flex flex-col items-center gap-2">
        <div className="text-lg font-semibold text-blue-800">Choose a Template</div>
      </div>

      <div className="mt-6 flex justify-center">
        <select
          value={selectedTemplateId}
          onChange={handleTemplateChange}
          className="block w-1/2 rounded-lg border border-gray-300 p-3 text-lg focus:border-blue-600 focus:outline-none"
        >
          <option value="" disabled>
            Select a Template
          </option>
          <optgroup label="Custom Templates">
            {dbTemplates.length > 0 ? (
              dbTemplates.map(template => {
                const templateId = String(template.id)
                return (
                  <option key={templateId} value={templateId}>
                    {template.name}
                  </option>
                )
              })
            ) : (
              <option value="" disabled>
                No server templates available
              </option>
            )}
          </optgroup>
          <optgroup label="Custom Templates (Local)">
            {localTemplates.length > 0 ? (
              localTemplates.map(template => {
                const templateId = String(template.id)
                return (
                  <option key={templateId} value={templateId}>
                    {template.name} (Local)
                  </option>
                )
              })
            ) : (
              <option value="" disabled>
                No local templates available
              </option>
            )}
          </optgroup>
          <optgroup label="Bola Default Templates">
            {data.procedureTemplates.map(template => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </optgroup>
        </select>
      </div>
    </div>
  )
}
