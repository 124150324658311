import { type ClassValue, clsx } from 'clsx'
import { toast } from 'sonner'
import { twMerge } from 'tailwind-merge'
import type { IEncounter, ITemplate, ITemplateResponse, ITemplateSection } from '@/models'
import { CustomTemplate } from './interfaces/interface'
import { STAGE_3_VIEWS } from './utils/constants'

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (e) {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  toast.success('Copied to clipboard')
}

export const generatePdf = async ({ fileName, text }: { fileName: string; text: string }) => {
  const { jsPDF } = await import('jspdf')
  const doc = new jsPDF()

  const adjustedFileName = `${fileName.replace(/[^a-zA-Z0-9,\s]/g, '_')}.pdf`

  // a4 size = 210mm x 297mm
  // 1 inch margins = 25.4mm
  doc.text(text, 25.4, 25.4, { maxWidth: 210 - 25.4 * 2 })
  doc.save(adjustedFileName)
}

export const generatePdfFromSections = async ({
  activeTemplate,
  fileName,
  response,
  deletedSections
}: {
  activeTemplate: ITemplate
  fileName: string
  response: ITemplateResponse | null
  deletedSections: IEncounter['deletedSections']
}) => {
  if (!response) {
    return
  }
  const adjustedFileName = `${fileName.replace(/[^a-zA-Z0-9,\s]/g, '_')}.pdf`
  const margin = 25.4
  const maxWidth = 210 - margin * 2
  const pageHeight = 297
  const { jsPDF } = await import('jspdf')
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4'
  })

  const addWrappedText = (text, initialY, fontSize = 12, fontStyle = 'normal') => {
    const lineHeight = fontSize * 0.5
    let y = initialY
    pdf.setFontSize(fontSize)
    pdf.setFont('helvetica', fontStyle)
    const textLines = pdf.splitTextToSize(text, maxWidth)
    return textLines.reduce((y, line) => {
      if (y + lineHeight > pageHeight - margin * 2) {
        pdf.addPage()
        y = margin
      }
      pdf.text(line, margin, y)
      return (y += lineHeight)
    }, initialY)
  }

  activeTemplate?.sections
    .filter(section => !deletedSections.includes(section.id))
    .reduce((y, section) => {
      const content = response.sections[section.id] ?? ''
      y = addWrappedText(section.name, y, 14, 'bold')
      y = addWrappedText(content, y)
      return y + margin / 2
    }, margin)

  pdf.save(adjustedFileName)
}

export const formatEncounterDate = (date: Date) =>
  date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })

export const formatCustomTemplateResponse = highlightedContent => {
  if (!highlightedContent) {
    return ''
  }

  let plainTextContent = highlightedContent
    .replace(/<br\s*\/?>/gi, '\n')
    .replace(/<\/p>/gi, '\n\n')
    .replace(/<\/?[^>]+(>|$)/g, '')

  return plainTextContent
}

export const formatPatientSummary = (response: ITemplateResponse): string => {
  return Object.entries(response.sections)
    .filter(([_, content]) => content && content.trim())
    .map(([key, content]) => `${key.replace(/_/g, ' ')}\n${content}`)
    .join('\n\n')
}

export const formatTemplateResponse = (
  activeTemplate: ITemplate,
  response: ITemplateResponse,
  deletedSections: IEncounter['deletedSections'],
  isTranscript: boolean = false
): string => {
  if (isTranscript) {
    return Object.values(response.sections).join('\n')
  }

  return (
    activeTemplate?.sections
      .filter(section => !deletedSections.includes(section.id))
      .map(section => {
        const content = response.sections[section.id] ?? ''
        return `${section.name}\n${content}`
      })
      .join('\n\n') ?? ''
  )
}

export const formatResponse = (content: string): string => {
  return content ? content.replace(/\n/g, '<br/>') : ''
}

export const formatTimestamp = (): string => {
  const now = new Date()
  let hours = now.getHours()
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  const formattedHours = hours.toString().padStart(2, '0')
  return `${formattedHours}:${minutes} ${ampm}`
}

export const createNewCustomTemplateEncounter = (
  customTemplate: CustomTemplate,
  preset?: Partial<IEncounter>
): IEncounter => {
  return {
    id: 'new-custom-encounter' as IEncounter['id'],
    templateId: customTemplate?.id?.toString() || '',
    rawTranscript: null,
    templateRaw: '',
    transcript: {
      sections: {}
    },
    transcriptTemplate: {
      id: 'transcript' as IEncounter['templateId'],
      name: customTemplate.name || 'Custom Transcript',
      isHardCoded: false,
      sections: [],
      showClinicalMap: true
    },
    note: null,
    patientSummary: null,
    progress: { sections: {} },
    deletedSections: [],
    isTranscribing: false,
    isGeneratingNote: false,
    isGeneratingPatientSummary: false,
    wasNoteUpdatedAfterGeneration: false,
    isSpotTranscribing: false,
    flatTranscript: '',
    speakerTranscript: '',
    createdAt: new Date(),
    insuranceNarrative: {
      sections: {
        narrative: ''
      }
    },
    isGeneratingInsuranceNarrative: false,
    ...preset
  }
}

export const createNewEncounter = (template: ITemplate, preset?: Partial<IEncounter>): IEncounter => {
  const sectionTimestamp: string = formatTimestamp()

  return {
    id: 'new-encounter' as IEncounter['id'],
    templateId: template.id,
    rawTranscript: null,
    templateRaw: null,
    transcript: {
      sections: {
        [sectionTimestamp]: ''
      }
    },
    transcriptTemplate: {
      id: 'transcript' as ITemplate['id'],
      name: 'Transcript',
      isHardCoded: template.isHardCoded,
      sections: [{ id: sectionTimestamp as ITemplateSection['id'], name: sectionTimestamp, requiredInfo: [] }],
      showClinicalMap: template.showClinicalMap
    },
    note: null,
    patientSummary: null,
    progress: { sections: {} },
    deletedSections: [],
    isTranscribing: false,
    isGeneratingNote: false,
    isGeneratingPatientSummary: false,
    isGeneratingInsuranceNarrative: false,
    wasNoteUpdatedAfterGeneration: false,
    isSpotTranscribing: false,
    flatTranscript: '',
    speakerTranscript: '',
    createdAt: new Date(),
    insuranceNarrative: {
      sections: {
        narrative: ''
      }
    },
    ...preset
  }
}

// map stage for header
export const stageMap: { [key: string]: number } = {
  Setup: 1,
  ProcedureSetup: 1, // Included in stage 1
  Transcript: 2,
  Template: 2, // Used when activeTemplate.isHardCoded is true
  Note: 3,
  Summary: 3
}

export const getStage = (view: string, isHardCoded: boolean): number => {
  if (view === 'Template' || view === 'CustomTemplate') return 2
  if (STAGE_3_VIEWS.includes(view)) return 3
  return stageMap[view] || 1
}

export const getStageState = (currentStage: number, targetStage: number): 'Completed' | 'Active' | 'Upcoming' => {
  if (currentStage < targetStage) {
    return 'Upcoming'
  }
  if (currentStage === targetStage) {
    return 'Active'
  }
  return 'Completed'
}

export const getSectionContent = (section: any): string | null => {
  try {
    if (typeof section === 'string') {
      return section
    } else if (typeof section === 'object' && 'content' in section) {
      return section.content
    }
    return null
  } catch (error) {
    return null
  }
}
