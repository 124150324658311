import React from 'react'
import { useStore } from '@/store'
import ToggleButton from '@/components/ui/ToggleButton'
import { AMBIENT_BUTTON_TEXT } from '@/utils/constants'

const AmbientToggle = () => {
  const isAmbientOn = useStore(state => state.ambientMode)
  const setAmbientMode = useStore(state => state.setAmbientMode)

  return (
    <div className="flex flex-col items-center gap-2">
      <ToggleButton
        checked={isAmbientOn}
        onChange={setAmbientMode}
        onText={AMBIENT_BUTTON_TEXT.ON}
        offText={AMBIENT_BUTTON_TEXT.OFF}
        height={24}
        width={150}
        sliderSize={16}
        padding={4}
        fontSize="0.75rem"
      />

      {isAmbientOn && (
        <p className="mt-2 max-w-sm text-center text-xs text-gray-600">
          <strong>Disclaimer:</strong> Make sure to obtain consent if you plan to capture the patient's voice in regions
          where this is required.
        </p>
      )}
    </div>
  )
}

export default AmbientToggle
