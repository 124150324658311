import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import posthog from 'posthog-js'
import { useEffect, useRef, useState } from 'react'
import AmbientToggle from './components/AmbientToggle'
import { Header } from '@/components/Header'
import { MicButton } from '@/components/MicButton'
import { MicLevel } from '@/components/MicLevel'
import { NoteSelect } from '@/components/NoteSelect'
import { NoteSidebar } from '@/components/NoteSidebar'
import { ProcedureSelect } from '@/components/ProcedureSelect'
import { ScreenLoader } from '@/components/ScreenLoader'
import { Settings } from '@/components/Settings'
import { SummarySidebar } from '@/components/SummarySidebar'
import { TextEditor } from '@/components/TextEditor'
import { TranscriptSidebar } from '@/components/TranscriptSidebar'
import { Label, ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import { SocketApi } from '@/services/SocketApi'
import { IStore, useStore } from '@/store'
import { cn, createNewCustomTemplateEncounter, createNewEncounter } from '@/util'
import { CreateCustomTemplate } from './components/customTemplate/CreateCustomTemplate'
import { CreateCustomTemplateSidebar } from './components/customTemplate/CreateCustomTemplateSidebar'
import { Edit } from './components/Edit'
import { EditSidebar } from './components/EditSidebar'
import { Templates } from './components/templates/Templates'
import CustomTemplate from './components/customTemplate/CustomTemplate'
import { CustomTemplateEdit } from './components/customTemplate/CustomTemplateEdit'
import { PatientNarrative } from './components/customTemplate/PatientNarrative'
import { AMBIENT_SUPPORTED_MODULES, MIC_BUTTON_VIEWS, HIDE_TRANSCRIPTION_VIEWS, tabForViews } from './utils/constants'
import { InsuranceNarrative } from './components/customTemplate/InsuranceNarrative'
import CustomTemplateSidebar from './components/customTemplate/CustomTemplateSidebar'
import HeaderTabs from './components/HeaderTab'
import SoapInsuranceNarrative from './components/SoapInsuranceNarrative'
import { InsuranceSidebar } from './components/InsuranceSidebar'
import SystemBanner from './components/SystemBanner'

SocketApi.getInstance()
const queryClient = new QueryClient()
export const App = () => {
  const view = useStore(state => state.view)
  const encounter = useStore(state => state.encounter)
  const activeTemplate = useStore(state => state.activeTemplate)
  const activeCustomTemplate = useStore(state => state.activeCustomTemplate)
  const customTemplate = useStore(state => state.customTemplate)
  const hideTranscription = useStore(state => state.hideTranscription)
  const licenseKey = useStore(state => state.licenseKey)
  const setView = useStore(state => state.setView)
  const setEncounter = useStore(state => state.setEncounter)
  const updateEncounter = useStore(state => state.updateEncounter)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  const shouldShowMicButton = () => {
    return (
      MIC_BUTTON_VIEWS.has(view) &&
      (activeTemplate || activeCustomTemplate) &&
      !(HIDE_TRANSCRIPTION_VIEWS.includes(view) && hideTranscription)
    )
  }

  useEffect(() => {
    const scrollArea = scrollAreaRef.current
    if (scrollArea) {
      const isOverflowing = scrollArea.scrollHeight > scrollArea.clientHeight
      if (view === 'Transcript' && isOverflowing && encounter?.isTranscribing) {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }, [view, encounter?.isTranscribing])

  const hasTabs = !!encounter?.note || !!encounter?.patientSummary || tabForViews.includes(view)
  const isGenerating = !!(encounter?.isGeneratingNote || encounter?.isGeneratingPatientSummary)

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col">
        <Header />
        <SystemBanner />
        {isGenerating && <ScreenLoader />}
        <div
          className={cn(
            'flex flex-1 flex-col items-stretch justify-center overflow-hidden md:flex-row-reverse',
            isGenerating && 'hidden'
          )}
        >
          <div
            className={cn(
              'flex w-full flex-col items-stretch justify-start gap-4',
              view === 'ProcedureSetup' ? 'md:w-[750px]' : 'md:w-[600px]'
            )}
          >
            {(view === 'Setup' || view === 'ProcedureSetup') && (
              <div className="flex flex-col justify-center gap-12">
                <div className="flex flex-col gap-3">{view === 'Setup' ? <NoteSelect /> : <ProcedureSelect />}</div>
                {(activeTemplate || activeCustomTemplate) && (
                  <div className="flex flex-col justify-center gap-4">
                    <div className="flex flex-col items-center">
                      <Label className="mb-3">Microphone input level</Label>
                      <MicLevel />
                    </div>
                    <Settings open={isSettingsOpen} onOpenChange={setIsSettingsOpen} />
                  </div>
                )}
              </div>
            )}
            <ScrollArea className="h-full">
              <div className="flex flex-1 flex-col items-stretch justify-start">
                {view === 'Transcript' && <TranscriptSidebar />}
                {view === 'Template' && <TranscriptSidebar />}
                {view === 'Edit' && <EditSidebar />}
                {view === 'Note' && <NoteSidebar />}
                {view === 'Summary' && <SummarySidebar />}
                {view === 'CreateCustomTemplate' && <CreateCustomTemplateSidebar />}
                {view === 'CustomTemplate' && <CustomTemplateSidebar />}
                {view === 'CustomTemplateEdit' && <CustomTemplateSidebar />}
                {view === 'CustomTemplatePatientNarrative' && <CustomTemplateSidebar />}
                {view === 'InsuranceNarrative' && <CustomTemplateSidebar />}
                {view === 'SoapExamInsuranceNarrative' && <InsuranceSidebar />}
              </div>
            </ScrollArea>
          </div>
          <div
            className={cn(
              'relative flex flex-1 overflow-hidden md:w-[calc(100%-510px)] md:flex-none',
              view === 'Setup' || view === 'ProcedureSetup' ? 'md:w-0' : 'border-t md:border-r md:border-t-0'
            )}
          >
            <ScrollArea ref={scrollAreaRef} className="flex-1">
              <Tabs
                value={view}
                onValueChange={tab => {
                  const view = tab as IStore['view']
                  if (scrollAreaRef.current) {
                    scrollAreaRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  const events: Record<IStore['view'], string> = {
                    Setup: 'user_clicked_setup_tab',
                    Transcript: 'user_clicked_transcript_tab',
                    Note: 'user_clicked_note_tab',
                    Summary: 'user_clicked_summary_tab',
                    Template: 'user_clicked_template_tab',
                    Edit: 'user_clicked_edit_tab',
                    ProcedureSetup: 'user_clicked_procedure_setup_tab',
                    CreateCustomTemplate: 'user_clicked_create_custom_template_tab',
                    CustomTemplate: 'user_clicked_custom_template',
                    CustomTemplateEdit: 'user_clicked_custom_template_edit',
                    CustomTemplatePatientNarrative: 'user_clicked_custom_template_patient_narrative',
                    InsuranceNarrative: 'user_clicked_insurance_narrative',
                    SoapExamInsuranceNarrative: 'user_clicked_insurance_soap'
                  }
                  updateEncounter({ isTranscribing: false })
                  posthog.capture(events[view], {
                    bolaLicenseKey: licenseKey,
                    bolaSessionId: encounter?.id
                  })
                  setView(view)
                }}
                defaultValue={view}
              >
                <HeaderTabs view={view} customTemplate={customTemplate} encounter={encounter} hasTabs={hasTabs} />
                {!isGenerating && (
                  <>
                    <TabsContent value="Template">
                      <Templates />
                    </TabsContent>
                    <TabsContent value="Edit">
                      <Edit />
                    </TabsContent>
                    <TabsContent value="Transcript">
                      <TextEditor type="Transcript" />
                    </TabsContent>
                    <TabsContent value="Note">
                      <TextEditor type="Note" />
                    </TabsContent>
                    <TabsContent value="Summary">
                      <TextEditor type="PatientSummary" />
                    </TabsContent>
                    <TabsContent value="SoapExamInsuranceNarrative">
                      <SoapInsuranceNarrative />
                    </TabsContent>
                    <TabsContent value="CreateCustomTemplate">
                      <CreateCustomTemplate />
                    </TabsContent>
                    <TabsContent value="CustomTemplate">
                      <CustomTemplate />
                    </TabsContent>
                    <TabsContent value="CustomTemplateEdit">
                      <CustomTemplateEdit />
                    </TabsContent>
                    <TabsContent value="CustomTemplatePatientNarrative">
                      <PatientNarrative />
                    </TabsContent>
                    <TabsContent value="InsuranceNarrative">
                      <InsuranceNarrative />
                    </TabsContent>
                    <div ref={messagesEndRef} />
                  </>
                )}
              </Tabs>
            </ScrollArea>
          </div>
        </div>
      </div>
      {shouldShowMicButton() && (
        <div
          className={`pointer-events-none left-0 right-0 z-50 flex items-end justify-center transition-all duration-300 ease-out ${
            view === 'Setup' || view === 'ProcedureSetup' ? '' : 'fixed bottom-0'
          }`}
        >
          <div className="pointer-events-auto flex flex-col items-center justify-center">
            <MicButton
              onStart={() => {
                if (view === 'Setup' || view === 'ProcedureSetup' || view === 'CustomTemplate') {
                  posthog.capture('user_clicked_mic_button_from_main_page', {
                    bolaLicenseKey: licenseKey,
                    bolaSessionId: encounter?.id
                  })

                  if (activeCustomTemplate) {
                    posthog.capture('user_clicked_mic_button_from_custom_template', {
                      bolaSessionId: encounter?.id,
                      bolaCustomTemplate: customTemplate.currentCustomTemplateId
                    })
                    setView('CustomTemplate')
                    setEncounter(createNewCustomTemplateEncounter(activeCustomTemplate, { isTranscribing: true }))
                  } else if (activeTemplate) {
                    setView(activeTemplate.isHardCoded ? 'Template' : 'Transcript')
                    setEncounter(createNewEncounter(activeTemplate, { isTranscribing: true }))
                  }
                }
              }}
              disabled={false}
            />
            {view === 'Setup' &&
              activeTemplate &&
              AMBIENT_SUPPORTED_MODULES.includes(activeTemplate.id || activeTemplate.name) && <AmbientToggle />}
          </div>
        </div>
      )}
    </QueryClientProvider>
  )
}
