import React from 'react'
import { ToggleButtonProps } from '@/interfaces/interface'

const ToggleButton: React.FC<ToggleButtonProps> = ({
  checked,
  onChange,
  onText,
  offText,
  height = 24,
  width = 44,
  sliderSize = 16,
  padding = 4,
  fontSize = '0.75rem',
  className = ''
}) => {
  return (
    <button
      onClick={() => onChange(!checked)}
      className={`relative flex items-center justify-between rounded-full transition-colors ${
        checked ? 'bg-blue-600 text-white' : 'bg-gray-400 text-black'
      } ${className}`}
      style={{
        height: `${height}px`,
        width: `${width}px`,
        padding: `0 ${padding}px`
      }}
    >
      {onText && offText && (
        <span className="absolute inset-0 flex items-center justify-center font-medium" style={{ fontSize }}>
          {checked ? onText : offText}
        </span>
      )}

      <div
        className="absolute rounded-full bg-white transition-transform duration-300 ease-in-out"
        style={{
          width: `${sliderSize}px`,
          height: `${sliderSize}px`,
          transform: `translateX(${checked ? width - sliderSize - padding - 2 : padding}px)`,
          top: '50%',
          translate: '0 -50%'
        }}
      />
    </button>
  )
}

export default ToggleButton
