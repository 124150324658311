import { useEffect, useRef, useState } from 'react'

import { MicrophoneEvent } from '@/services/Microphone'
import { SocketApi } from '@/services/SocketApi'

import { MicLevel } from './MicLevel'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from './ui'

const socketApi = SocketApi.getInstance();

interface ISettingsProps {
  open: boolean
  onOpenChange(open: boolean): void
}

export const Settings: React.FC<ISettingsProps> = ({ open, onOpenChange }) => {
  const [activeMicId, setActiveMicId] = useState<MediaDeviceInfo['deviceId'] | null>(null)
  const [mics, setMics] = useState<MediaDeviceInfo[]>([])
  const audioLevelRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const microphone = socketApi.getMicrophone()

    const removeDeviceListListener = microphone.on(MicrophoneEvent.DEVICE_CHANGE, async () => {
      setMics(await microphone.listDevices())
    })

    const removeAudioLevelListener = microphone.on(MicrophoneEvent.LEVEL_CHANGE, ({ detail: level }) => {
      audioLevelRef.current?.style.setProperty('transform', `translateX(-${100 - (level * 100 || 0)}%)`)
    })

    void microphone.getDefaultDeviceId().then(defaultDeviceId => {
      setActiveMicId(microphone.getCurrentDeviceId() ?? defaultDeviceId)
    })

    void microphone.listDevices().then(devices => setMics(devices))

    return () => {
      removeDeviceListListener()
      removeAudioLevelListener()
    }
  }, [])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <div className="flex flex-col items-center">
          <Button variant="link" size="default">
            Advanced Settings
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Advanced settings</DialogTitle>
        </DialogHeader>
        <div className="w-full overflow-hidden px-1 py-4">
          <div className="mb-4 flex flex-col gap-2">
            <Label>Microphone</Label>
            <Select
              value={activeMicId ?? undefined}
              onValueChange={value => {
                void socketApi
                  .getMicrophone()
                  .setCurrentDeviceId(value)
                  .then(() => setActiveMicId(socketApi.getMicrophone().getCurrentDeviceId()))
              }}
            >
              <SelectTrigger className="flex-1 [&>span]:block [&>span]:w-full [&>span]:overflow-ellipsis [&>span]:text-left">
                <SelectValue placeholder="Select microphone" />
              </SelectTrigger>
              <SelectContent>
                {mics.map((mic, index) => (
                  <SelectItem
                    key={mic.deviceId || index}
                    value={mic.deviceId || index.toString()}
                    className="relative w-full max-w-[28rem] [&>span]:last:block [&>span]:last:w-full [&>span]:last:overflow-hidden [&>span]:last:overflow-ellipsis [&>span]:last:text-nowrap [&>span]:last:text-left"
                  >
                    {mic.label || `Microphone ${mic.deviceId}`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <MicLevel />
        </div>
        <DialogFooter>
          <Button
            type="submit"
            onClick={() => {
              onOpenChange(false)
            }}
          >
            Save changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
