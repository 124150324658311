import React from 'react'
import { useStore } from '@/store'
import ToggleButton from '@/components/ui/ToggleButton'
import { TRANSCRIPTION_TOGGLE_TEXT } from '@/utils/constants'

const TranscriptionToggle = () => {
  const isTranscriptionVisible = useStore(state => state.hideTranscription)
  const setHideTranscription = useStore(state => state.setHideTranscription)

  return (
    <div className="mb-4">
      <ToggleButton
        checked={isTranscriptionVisible}
        onChange={setHideTranscription}
        onText={TRANSCRIPTION_TOGGLE_TEXT.ON}
        offText={TRANSCRIPTION_TOGGLE_TEXT.OFF}
        height={30}
        width={190}
        sliderSize={20}
        padding={6}
        fontSize="0.775rem"
      />
    </div>
  )
}

export default TranscriptionToggle
