import React, { useState } from 'react'
import { ChevronDown } from 'lucide-react'
import { Button, Progress, Label } from '@/components/ui'
import { cn } from '@/util'
import { useStore } from '@/store'
import ToggleButton from '../ui/ToggleButton'
import VariableList from './VariableList'
import { CustomTemplateVariable } from '@/interfaces/interface'
import { ADDITIONAL_NOTES } from '@/utils/constants'

export const CustomTemplateClinicalMap: React.FC = () => {
  const [isShowingDetails, setIsShowingDetails] = useState(true)
  const [showOptions, setShowOptions] = useState(false)

  const { view, activeCustomTemplate, customTemplate } = useStore(state => ({
    view: state.view,
    activeCustomTemplate: state.activeCustomTemplate,
    customTemplate: state.customTemplate
  }))

  let variables: CustomTemplateVariable[] = []
  const filledValues = customTemplate?.filledValues ?? {}

  if (view === 'CustomTemplate' && activeCustomTemplate) {
    variables = activeCustomTemplate.variables ?? []
  } else if (customTemplate.generatedTemplate) {
    variables = customTemplate.generatedTemplate?.variables ?? []
  }

  const isVariableFilled = (variableName: string): boolean => {
    return !!filledValues[variableName]?.length
  }

  const totalVariables = isVariableFilled(ADDITIONAL_NOTES)
    ? variables.length
    : variables?.filter(variable => variable.name !== ADDITIONAL_NOTES).length
  const completedVariables = variables?.filter(variable => isVariableFilled(variable?.name)).length
  const overallProgress = totalVariables > 0 ? Math.round((completedVariables / totalVariables) * 100) : 0

  return (
    <div id="customTemplateClinicalMap">
      <div className="flex flex-row items-center justify-between">
        <Label className="-mb-1 text-lg text-black">Clinical map</Label>
        <div className="flex items-center gap-4">
          {variables.length > 0 && (
            <div className="flex items-center gap-2">
              <Label className="text-sm text-gray-700">Show Optional Values</Label>
              <ToggleButton
                checked={showOptions}
                onChange={setShowOptions}
                height={20}
                width={50}
                sliderSize={14}
                padding={3}
                fontSize="0.75rem"
              />
            </div>
          )}
          <Button
            variant="ghost"
            className="-mr-4 gap-1 text-[#0f172a] md:flex"
            onClick={() => setIsShowingDetails(prev => !prev)}
          >
            {isShowingDetails ? 'Hide details' : 'Show details'}
            <ChevronDown
              className={cn('size-4 transition-all duration-150 ease-out', isShowingDetails && 'rotate-180')}
            />
          </Button>
        </div>
      </div>
      <div className="mb-4"></div>
      {isShowingDetails && (
        <VariableList variables={variables} showOptions={showOptions} isVariableFilled={isVariableFilled} />
      )}
      <div className="mt-6 flex flex-row items-center gap-2">
        <Progress className="h-2 w-full bg-border" value={overallProgress} />
        <div className="text-[15px] font-bold text-[#00052f]">{overallProgress}%</div>
      </div>
    </div>
  )
}
