import { Mic, Loader2 } from 'lucide-react'
import posthog from 'posthog-js'
import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { cn } from '@/util'
import { HIDE_TRANSCRIPTION_VIEWS, MIC_BUTTON_TEXT } from '@/utils/constants'
import TranscriptionToggle from './TranscriptionToggle'

interface IMicButtonProps {
  onStart?(): void
  onStop?(): void
  disabled?: boolean
  isOverText?: boolean
}

export const MicButton: React.FC<IMicButtonProps> = ({ onStart, onStop, disabled, isOverText }) => {
  const { view, encounter, updateEncounter, ambientMode, hideTranscription } = useStore(state => ({
    view: state.view,
    encounter: state.encounter,
    updateEncounter: state.updateEncounter,
    ambientMode: state.ambientMode,
    hideTranscription: state.hideTranscription
  }))

  const toggleMic = () => {
    window.getSelection()?.removeAllRanges()
    updateEncounter({ isSpotTranscribing: false })

    const isTranscribing = encounter?.isTranscribing
    posthog.capture(isTranscribing ? 'user_stopped_transcribing' : 'user_started_transcribing')
    isTranscribing ? onStop?.() : onStart?.()

    updateEncounter({ isTranscribing: !isTranscribing })
  }

  const isMainMicOn = encounter?.isTranscribing && !encounter?.isSpotTranscribing
  const isSetup = view === 'Setup' || view === 'ProcedureSetup'
  const micColor = isSetup || isMainMicOn ? '#fff' : '#00052f'
  const micBg = isMainMicOn ? 'bg-[#ff0000] hover:bg-[#ff3333]' : 'bg-[#d3d3d3] hover:bg-[#acacac]'
  const textColor = isMainMicOn ? 'text-[#ff0000]' : isSetup ? 'text-primary' : 'text-[#00052f]'

  const getMicButtonText = () => {
    if (isSetup) {
      return MIC_BUTTON_TEXT.SETUP
    } else if (isMainMicOn && ambientMode && view === 'Transcript') {
      return MIC_BUTTON_TEXT.AMBIENT_LISTENING
    } else if (isMainMicOn) {
      return MIC_BUTTON_TEXT.RECORDING
    } else {
      return MIC_BUTTON_TEXT.PAUSED
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-3">
      {hideTranscription && isMainMicOn && (
        <div className="mb-3 flex flex-col items-center">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Loader2 className="size-5 animate-spin" />
            Processing speech...
          </div>

          <div className="mt-2 max-w-sm text-center text-xs text-gray-500">
            Your speech is being transcribed in real time, and final notes will be refined for accuracy.
          </div>
        </div>
      )}

      <div className="relative flex items-center justify-center">
        <div
          className={cn(
            'absolute aspect-square h-[140px] w-[140px] rounded-full border-[5px] border-[#acacac] bg-background',
            isMainMicOn && 'animate-pulse-ring border-[20px] border-[#ff8e8e]'
          )}
        />
        <Button
          className={cn(
            'relative z-10 h-28 w-28 flex-shrink-0 rounded-full shadow-none transition-none',
            !isSetup && micBg
          )}
          onClick={toggleMic}
          disabled={disabled}
        >
          <Mic className="h-10 w-10" color={micColor} />
        </Button>
      </div>

      <div
        className={cn(
          'mt-1 rounded-full px-4 py-2 text-center text-sm font-medium transition-all duration-150 ease-out',
          textColor,
          isSetup && 'relative',
          !isSetup && isOverText && 'bg-background shadow'
        )}
      >
        {getMicButtonText()}
      </div>

      {HIDE_TRANSCRIPTION_VIEWS.includes(view) && (
        <div className="mt-3 flex w-full flex-col items-center pb-10">
          <TranscriptionToggle />
          {!hideTranscription && (
            <div className="mt-3 max-w-lg rounded-md border border-gray-200 bg-gray-100 px-4 py-2 text-center text-sm text-gray-700 shadow-sm">
              Note: Transcription displays raw speech in real time; the final note will be refined for accuracy.
            </div>
          )}
        </div>
      )}
    </div>
  )
}
