import posthog from 'posthog-js'
import { useEffect, useState } from 'react'
import bolaLogo from '/assets/bola-logo.svg'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui'
import { useStore } from '@/store'
import { cn } from '@/util'

import { Stepper } from './Stepper'
import { SocketApi } from '@/services/SocketApi'

const Socket = SocketApi.getInstance();
const CANNY_APP_ID = import.meta.env.VITE_CANNY_APP_ID

export const Header = () => {
  const view = useStore(state => state.view)
  const setView = useStore(state => state.setView)
  const startNewEncounter = useStore(state => state.startNewEncounter)
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false)
  const clearCustomTemplate = useStore(state => state.clearCustomTemplate)
  const currentCustomTemplateId = useStore(state => state.customTemplate?.currentCustomTemplateId)
  const encounterId = useStore(state => state.encounter?.id)

  useEffect(() => {
    if (window.Canny) {
      window.Canny('initChangelog', {
        appID: CANNY_APP_ID,
        position: 'bottom',
        align: 'right',
        theme: 'light'
      })
    }

    return () => {
      if (window.Canny) {
        window.Canny('closeChangelog')
      }
    }
  }, [view])

  const handleCreateCustomTemplate = () => {
    clearCustomTemplate()
    setView('CreateCustomTemplate')
  }

  return (
    <header className="flex h-20 items-center justify-between gap-2 border-b px-8 md:h-24">
      <img src={bolaLogo} className="h-8" alt="Bola Logo" />
      <div className="absolute left-1/2 -translate-x-1/2 transform">
        <Stepper />
      </div>

      <div className="flex items-center gap-4">
        {view !== 'CreateCustomTemplate' && (
          <button
            onClick={handleCreateCustomTemplate}
            className="rounded-lg bg-blue-600 px-4 py-2 font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none"
          >
            + Create Custom Template
          </button>
        )}

        <Dialog
          open={isCloseDialogOpen}
          onOpenChange={open => {
            posthog.capture('user_clicked_close_button')
            setIsCloseDialogOpen(open)
          }}
        >
          <DialogTrigger asChild>
            <Button
              variant="link"
              className={cn('-mr-4 flex-shrink-0', view === 'Setup' && 'pointer-events-none invisible')}
            >
              New Note
            </Button>
          </DialogTrigger>
          <Dialog>
            {view === 'Setup' && (
              <Button variant="link" className={cn('-mr-4 flex-shrink-0')} cannyChangelog>
                What's new?
              </Button>
            )}
          </Dialog>
          <DialogContent className="z-[9999999] mx-auto max-w-lg">
            <DialogHeader>
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogDescription>This will discard everything and cannot be undone.</DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  posthog.capture('user_cancelled_discard_button')
                  setIsCloseDialogOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="destructive"
                onClick={() => {
                  posthog.capture('user_confirmed_discard_button', {
                    bolaSessionId: encounterId,
                    bolaCustomTemplate: currentCustomTemplateId
                  })
                  setIsCloseDialogOpen(false)
                  clearCustomTemplate()
                  startNewEncounter()
                  Socket.finishEncounter()
                }}
              >
                Yes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </header>
  )
}
