import React, { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Dialog,
  DialogDescription,
  Input
} from '@/components/ui'
import { useStore } from '@/store'
import { toast } from 'sonner'
import { CustomTemplateClinicalMap } from './CustomTemplateClinicalMap'
import * as Sentry from '@sentry/react'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import posthog from 'posthog-js'
import { Loader } from '../ui/loader'
import { ReportIssue } from '../ReportIssue'

const socket = SocketApi.getInstance();

export const CreateCustomTemplateSidebar = () => {
  const { userTemplate, generatedTemplate, currentCustomTemplateId, isTemplateGenerated, isPending, licenseKey } =
    useStore(state => ({
      userTemplate: state.customTemplate.userTemplate,
      generatedTemplate: state.customTemplate.generatedTemplate,
      currentCustomTemplateId: state.customTemplate.currentCustomTemplateId,
      isTemplateGenerated: state.customTemplate.isTemplateGenerated,
      isPending: state.customTemplate.isPending,
      licenseKey: state.licenseKey
    }))

  const setGeneratedTemplate = useStore(state => state.setGeneratedTemplate)
  const setCurrentCustomTemplateId = useStore(state => state.setCurrentCustomTemplateId)
  const setIsTemplateGenerated = useStore(state => state.setIsTemplateGenerated)
  const setIsPending = useStore(state => state.setIsPending)
  const setView = useStore(state => state.setView)

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [userTemplateName, setUserTemplateName] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    socket.onUniqueListener(SocketApiListenEvents.DB_CUSTOM_TEMPLATE_SAVED, data => {
      if (data?.id) {
        setCurrentCustomTemplateId(data.id)
        setIsPending(false)
        setIsUpdate(true)
        toast.success('Template saved successfully!')
        setIsConfirmDialogOpen(true)
      } else {
        toast.error('Failed to save template. No ID returned.')
      }
    })
  }, [setCurrentCustomTemplateId, setIsPending, socket.getSocket()])

  const handleCreateTemplate = () => {
    posthog.capture('user_clicked_create_template')

    if (!userTemplate) {
      toast.error('No template content found. Please paste your template.')
      return
    }
    try {
      socket.createCustomTemplate(userTemplate)
      setIsPending(true)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleSaveTemplate = () => {
    posthog.capture('user_clicked_save_template')

    if (!generatedTemplate || !userTemplate) {
      toast.error('No template content found. Please create a template first.')
      return
    }

    try {
      if (isUpdate && currentCustomTemplateId) {
        socket.updateCustomTemplate({
          id: currentCustomTemplateId,
          licenseKey: licenseKey,
          name: userTemplateName?.trim() || generatedTemplate?.name || 'Unnamed Template',
          note: generatedTemplate?.note,
          type: generatedTemplate?.type,
          variables: generatedTemplate?.variables,
          updatedAt: new Date()
        })
        toast.success('Template updated successfully!')
      } else {
        socket.saveCustomTemplate(licenseKey, {
          name: userTemplateName?.trim() || generatedTemplate?.name || 'Unnamed Template',
          note: generatedTemplate?.note,
          type: generatedTemplate?.type,
          variables: generatedTemplate?.variables,
          createdAt: new Date(),
          updatedAt: null,
          archivedAt: null
        })
      }
      setIsSaveDialogOpen(false)
    } catch (error) {
      console.error('Error saving template:', error)
      Sentry.captureException(error)
      toast.error('Failed to save template. Please try again.')
    }
  }

  const handleRedoButton = () => {
    setGeneratedTemplate(null)
    setIsTemplateGenerated(false)
    toast.success('Template reset to original')
  }

  const handleGoToHome = () => {
    setIsConfirmDialogOpen(false)
    setView('Setup')
  }

  const handleStayHere = () => {
    setIsConfirmDialogOpen(false)
  }

  return (
    <div className="flex h-full max-w-full flex-col overflow-hidden p-4">
      <div className="mb-4 rounded-md bg-gray-100 p-4">
        <h2 className="text-md mb-2 font-semibold">How to Use</h2>
        <ol className="list-inside list-decimal space-y-1 text-sm text-gray-700">
          <li>Paste your custom template.</li>
          <li>Click "Create Custom Template" to generate the clinical map.</li>
          <li>
            Use the <span className="text-[#E0C300]">★</span> icon to toggle variables between mandatory and optional:
            <ul className="ml-4 list-disc space-y-1">
              <li>
                Mandatory variables (marked with <span className="text-[#E0C300]">★</span>) must be dictated or filled
                in while using the template.
              </li>
              <li>Optional variables can be skipped while dictating.</li>
            </ul>
          </li>
          <li>Click "Edit Custom Template" to start over if needed.</li>
          <li>Click "Save Custom Template" to save it.</li>
        </ol>
      </div>

      <div className="max-w-full flex-1 overflow-y-auto overflow-x-hidden">
        <CustomTemplateClinicalMap />
      </div>

      <div className="mt-4 max-w-full space-y-2">
        <Button size="lg" onClick={handleCreateTemplate} disabled={isPending || isTemplateGenerated} className="w-full">
          {isPending ? 'Creating Custom Template' : 'Create Custom Template'} <Loader isPending={isPending} />
        </Button>

        {generatedTemplate && (
          <>
            <Button size="lg" onClick={handleRedoButton} className="w-full">
              Edit Custom Template
            </Button>
            <Button
              size="lg"
              className="w-full"
              onClick={() => {
                if (isUpdate) {
                  handleSaveTemplate()
                } else {
                  setIsSaveDialogOpen(true)
                }
              }}
            >
              {isUpdate ? 'Update Variable Requirements' : 'Save Custom Template'}
            </Button>
          </>
        )}
      </div>

      <Dialog open={isSaveDialogOpen} onOpenChange={setIsSaveDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save Custom Template</DialogTitle>
            <DialogDescription>Enter the name of the template</DialogDescription>
          </DialogHeader>
          <Input
            type="text"
            onInput={(e: any) => setUserTemplateName(e.target.value)}
            placeholder={generatedTemplate?.name || ''}
          />
          <DialogFooter>
            <Button onClick={() => setIsSaveDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveTemplate}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Go to Home Screen?</DialogTitle>
            <DialogDescription>
              Your custom template has been saved successfully. Would you like to return to the home screen?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleStayHere}>No, Stay Here</Button>
            <Button onClick={handleGoToHome}>Yes, Go to Home</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="mt-4">
        <ReportIssue />
      </div>
    </div>
  )
}
